import React, { useEffect } from 'react';

// import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import anime from 'animejs';
import styles from './IconPowerFail.module.scss';

const IconPowerFail = ({ shouldStart = false, id = '' }) => {
    const FailAnimation = () => {
        anime({
            targets: `.${id}powerLine`,
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'linear',
            duration: 800,
            direction: 'forward',
            loop: false,
        });
    };

    useEffect(() => {
        shouldStart ? FailAnimation() : null;
    });

    return (
        <div className={styles['IconPowerFail']}>
            <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 51 50">
                <path
                    d="m34.204 26.903 3.41-3.653-10-3.75 2.5-12.5-17.5 18.75 10 3.75-2.5 12.5 6.136-6.097"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    className={id + 'powerLine'}
                    d="m37.555 30.27-8.116 8.115m-.001-8.115 8.116 8.115"
                    stroke="#feca01"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
            </svg>
        </div>
    );
};

IconPowerFail.propTypes = {};

export default IconPowerFail;
